import './common/wdyr' // Developer tool to help understand why a component is rendering in non-production environments

import Layout, { Content } from 'antd/lib/layout/layout'
import classNames from 'classnames'
import { BackButtonOverridesWrapper } from '../components/BackButtonOverridesWrapper/BackButtonOverridesWrapper'
import { ActionMenuProvider } from '../components/TechnicianApp/BottomNavBar/ActionMenu/ActionMenuProvider'
import { CurrentVisitCardContainer } from '../components/TechnicianApp/BottomNavBar/CurrentVisitCard/CurrentVisitCardContainer'
import { CurrentVisitProvider } from '../components/TechnicianApp/BottomNavBar/CurrentVisitCard/CurrentVisitProvider'
import TechnicianAppBottomNavBar from '../components/TechnicianApp/BottomNavBar/TechnicianAppBottomNavBar'
import { PWAInstallPromptWrapper } from '../components/TechnicianApp/PWAInstallPrompt/PWAInstallPrompt'
import ScrollToTop from '../components/TechnicianApp/ScrollToTop/ScrollToTop'
import TechExpDrawersWrapper from '../components/TechnicianApp/TechExpDrawersContextWrapper/TechExpDrawersContextWrapper'
import TopNavbar from '../components/TechnicianApp/TopNavbar/TopNavBar'
import {
  AnalyticsWrapper,
  initializeDatadogRum,
} from '../providers/AnalyticsWrapper'
import { AppContextWrapper } from '../providers/AppContextWrapper'
import { BlockerWrapper } from '../providers/BlockerWrapper'
import { PlatformVersionWrapper } from '../providers/PlatformVersionWrapper'
import Router from '../providers/Router'
import '../themes/tech-exp-intercom-override.css'
import '../themes/tech-experience.less'
import { TechnicianReactRoutes } from '../utils/TechnicianReactRoutes'
import { Applications } from '../utils/application-type'
import { AuthenticatedAppWrapper, withApp } from './common/CommonAppWrapper'

initializeDatadogRum()

withApp(
  <AuthenticatedAppWrapper>
    <PWAInstallPromptWrapper>
      <TechExpDrawersWrapper>
        <Router
          applicationContext={Applications.TECHNICIAN}
          routes={TechnicianReactRoutes}
          routeWrapper={(
            children,
            header = <TopNavbar />,
            whiteBackground = false,
          ) => (
            <PlatformVersionWrapper>
              <BlockerWrapper>
                <AnalyticsWrapper>
                  <AppContextWrapper appType={Applications.TECHNICIAN}>
                    <BackButtonOverridesWrapper>
                      <CurrentVisitProvider>
                        <ActionMenuProvider>
                          <Layout
                            className={classNames(
                              'max-w-screen flex h-screen max-h-screen w-screen flex-col',

                              {
                                'bg-white': whiteBackground,
                              },
                            )}
                          >
                            <ScrollToTop />
                            {header}
                            <CurrentVisitCardContainer>
                              <Content className="relative flex min-h-full flex-1 flex-col items-center space-y-2 overflow-auto p-4">
                                {children}
                              </Content>
                            </CurrentVisitCardContainer>
                            <TechnicianAppBottomNavBar />
                          </Layout>
                        </ActionMenuProvider>
                      </CurrentVisitProvider>
                    </BackButtonOverridesWrapper>
                  </AppContextWrapper>
                </AnalyticsWrapper>
              </BlockerWrapper>
            </PlatformVersionWrapper>
          )}
        />
      </TechExpDrawersWrapper>
    </PWAInstallPromptWrapper>
  </AuthenticatedAppWrapper>,
)
